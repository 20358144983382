import { API_NAMES, initApi } from "../../utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../utils/HttpHelper";

const postGroupMemberData = (groupMemberObj, state, setState) => {
  if (groupMemberObj) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);
    const url = `/PersonOrgGroup`;
    return api?.executeObject ? api.executeObject(url, 'POST', groupMemberObj) : null;
  }
};

const getGroupMemberData = (personOrgGroupId, state, setState) => {
  if (personOrgGroupId) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const personOrgGroupIdForUrl = personOrgGroupId ? encodeURIComponent(personOrgGroupId) : 'NaN';
    const url = `/PersonOrgGroup/${personOrgGroupIdForUrl}`;
    return api.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getMemberGroupsData = (personId, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const url = `/OrgGroup/Registration/Person/${personIdForUrl}`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const getGroupQualificationEventsData = (personOrgGroupQualificationId, isSelected, state, setState) => {
  if (personOrgGroupQualificationId > 0 && (isSelected === false || isSelected === true)) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const personOrgGroupQualificationIdForUrl = personOrgGroupQualificationId ? encodeURIComponent(personOrgGroupQualificationId) : 'NaN';
    const url = `/GroupQualificationEvent/PersonOrgGroupQualification/${personOrgGroupQualificationIdForUrl}?isSelected=${isSelected}`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putGroupMemberData = (personOrgGroupId, groupMemberObj, state, setState) => {
  if (personOrgGroupId > 0 && groupMemberObj) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const personOrgGroupIdForUrl = personOrgGroupId ? encodeURIComponent(personOrgGroupId) : 'NaN';
    const url = `/PersonOrgGroup/${personOrgGroupIdForUrl}`;
    return api?.executeObject ? api.executeObject(url, 'PUT', groupMemberObj) : null;
  }
};

const deleteGroupMemberData = (personOrgGroupId, state, setState) => {
  if (personOrgGroupId > 0) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const personOrgGroupIdForUrl = personOrgGroupId ? encodeURIComponent(personOrgGroupId) : 'NaN';
    const url = `/PersonOrgGroup/${personOrgGroupIdForUrl}`;
    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const MEMBER_GROUPS_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MEMBER_GROUPS_QUALIFICATION_EVENT_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberGroupsData = {
  MEMBER_GROUPS_INITIAL_STATE,
  MEMBER_GROUPS_QUALIFICATION_EVENT_INITIAL_STATE,
  getGroupMemberData,
  getMemberGroupsData,
  getGroupQualificationEventsData,
  putGroupMemberData,
  postGroupMemberData,
  deleteGroupMemberData
};

export default MemberGroupsData;