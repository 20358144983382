import { useState } from "react";

import MemberGroupsData from "./MemberGroupsData";

const useMemberGroupsData = () => {
  const [memberGroupsState, setMemberGroupsState] = useState(MemberGroupsData.MEMBER_GROUPS_INITIAL_STATE);
  const [memberGroupsQualificationEventState, setMemberGroupQualificationEventState] = useState(MemberGroupsData.MEMBER_GROUPS_QUALIFICATION_EVENT_INITIAL_STATE);

  const postGroupMember = (groupMemberObj) => {
    return MemberGroupsData.postGroupMemberData(groupMemberObj, memberGroupsState, setMemberGroupsState);
  };

  const getMemberGroups = (personId) => {
    return MemberGroupsData.getMemberGroupsData(personId, memberGroupsState, setMemberGroupsState);
  };

  const getGroupMember = (personOrgGroupId) => {
    return MemberGroupsData.getGroupMemberData(personOrgGroupId, memberGroupsState, setMemberGroupsState);
  };

  const getGroupQualificationEvents = (personOrgGroupQualificationId, isSelected = false) => {
    return MemberGroupsData.getGroupQualificationEventsData(personOrgGroupQualificationId, isSelected, memberGroupsQualificationEventState, setMemberGroupQualificationEventState);
  };

  const putGroupMember = (personOrgGroupId, groupMemberObj) => {
    return MemberGroupsData.putGroupMemberData(personOrgGroupId, groupMemberObj, memberGroupsState, setMemberGroupsState);
  };

  const deleteGroupMember = (personOrgGroupId) => {
    return MemberGroupsData.deleteGroupMemberData(personOrgGroupId, memberGroupsState, setMemberGroupsState);
  };

  const resetMemberGroupsState = () => {
    setMemberGroupsState({ ...MemberGroupsData.MEMBER_GROUPS_INITIAL_STATE });
  };

  return {
    memberGroupsState,
    memberGroupsQualificationEventState,
    postGroupMember,
    getGroupMember,
    getMemberGroups,
    getGroupQualificationEvents,
    putGroupMember,
    deleteGroupMember,
    resetMemberGroupsState
  };
};

export default useMemberGroupsData;