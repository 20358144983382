import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import { navLinks } from '../../../UseNavLinks';
import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import useGroupScholasticAllAmericanInviteData from "../../../state/workflow/groupScholasticAllAmericanInvite/UseGroupScholasticAllAmericanInviteData";

import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useOrgGroupPropertiesData from "../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData";
import useMemberGroupsFileData from "../../../../common/state/memberGroupsFile/UseMemberGroupsFileData";
import useGroupFieldNamesData from "../../../../common/state/groupFieldNames/UseGroupFieldNamesData";

import Constants from '../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  displayModal: false,
  modalTitle: '',
  submitButtonText: '',
  notesLabel: '',
  stepStatus: ''
};

const useGroupScholasticAllAmericanInviteApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { environmentVariableState, SCHOLASTIC_ALL_AMERICAN_GROUP_TYPE_ID } = useEnvironmentVariableData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { groupScholasticAllAmericanInviteState, getGroupScholasticAllAmericanInviteWorkflow, putGroupScholasticAllAmericanInviteWorkflow
  } = useGroupScholasticAllAmericanInviteData();
  const { memberGroupsFileDownloadState, getGroupMemberGPAFile } = useMemberGroupsFileData();
  const [readonlyFormState, setReadonlyFormState] = useState(getInitialReadonlyFormState);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onApprovedClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(groupScholasticAllAmericanInviteState.objData));
    const groupInvite = location.state.groupInvite;

    putGroupScholasticAllAmericanInviteWorkflow(groupInvite.workflowId,
      createNewWorkflowObj(workflowObjCopy, groupInvite.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
        '', 0));
  };

  const onRejectClicked = () => {
    setModalState({
      ...modalState,
      displayModal: true,
      modalTitle: 'Reject Group Application',
      submitButtonText: 'Reject Group Application',
      notesLabel: 'Reject Notes',
      stepStatus: Constants.WORKFLOW_STATUS_REJECTED
    });
  };

  const onSendBackClicked = () => {
    setModalState({
      ...modalState,
      displayModal: true,
      modalTitle: 'Send Back Group Application',
      submitButtonText: 'Send Back Group Application',
      notesLabel: 'Send Back Notes',
      stepStatus: Constants.WORKFLOW_STATUS_SEND_BACK
    });
  };

  const onBackClicked = () => {
    navigate(navLinks.GROUP_SCHOLASTIC_ALL_AMERICA_INVITE, { state: { isRevisit: location.state?.isRevisit } });
  };

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(readonlyFormState.gpaUrl)
  };

  const onSubmitFormCallback = (formState) => {
    const workflowObjCopy = JSON.parse(JSON.stringify(groupScholasticAllAmericanInviteState.objData));
    const groupInvite = location.state.groupInvite;

    putGroupScholasticAllAmericanInviteWorkflow(groupInvite.workflowId,
      createNewWorkflowObj(workflowObjCopy, groupInvite.workflowStepId, modalState.stepStatus,
        formState.stepNotes, 0));
  };

  const onModalCancelClicked = () => {
    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (location.state?.groupInvite?.workflowId > 0) {
      getGroupScholasticAllAmericanInviteWorkflow(location.state.groupInvite.workflowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (SCHOLASTIC_ALL_AMERICAN_GROUP_TYPE_ID !== orgGroupPropertiesState.groupTypeId) {
        getOrgGroupProperties(SCHOLASTIC_ALL_AMERICAN_GROUP_TYPE_ID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, environmentVariableState]);

  useEffect(() => {
    if (location.state?.groupInvite && SCHOLASTIC_ALL_AMERICAN_GROUP_TYPE_ID === orgGroupPropertiesState.groupTypeId
      && orgGroupPropertiesState.isArrayLoading === false) {
      const groupInvite = location.state.groupInvite;

      setReadonlyFormState({
        ...readonlyFormState,
        personName: `${groupInvite.firstName || ''} ${groupInvite.lastName || ''}`,
        groupName: groupInvite.groupName || '',
        highSchoolName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL)?.fieldValue || '',
        highSchoolGraduationYear: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR)?.fieldValue || '',
        gpa: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA)?.fieldValue || '',
        gpaUrl: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA_FILE)?.fieldValue || '',
        paraStatusName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PARA_STATUS)?.fieldValue || '',
        openWaterStatusName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_OPEN_WATER_STATUS)?.fieldValue || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, orgGroupPropertiesState]);

  useEffect(() => {
    if (groupScholasticAllAmericanInviteState.isSaved === true) {
      navigate(navLinks.GROUP_SCHOLASTIC_ALL_AMERICA_INVITE, { state: { isRevisit: location.state?.isRevisit } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupScholasticAllAmericanInviteState.isSaved]);

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getGroupMemberGPAFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function getInitialReadonlyFormState() {
    return {
      personName: '',
      groupName: '',
      highSchoolName: '',
      highSchoolGraduationYear: '',
      gpa: '',
      gpaUrl: '',
      paraStatusName: '',
      openWaterStatusName: ''
    };
  }

  return {
    location,
    Constants,
    isSaving: groupScholasticAllAmericanInviteState.isSaving,
    isLoading: groupScholasticAllAmericanInviteState.isObjLoading || environmentVariableState.isLoading || orgGroupPropertiesState.isArrayLoading || memberGroupsFileDownloadState.isObjLoading,
    personOrgGroupId: location.state?.groupInvite?.personOrgGroupId,
    readonlyFormState,
    modalState,
    onApprovedClicked,
    onRejectClicked,
    onBackClicked,
    onSendBackClicked,
    onSubmitFormCallback,
    onModalCancelClicked,
    onDownloadUploadedFile
  };
};

export default useGroupScholasticAllAmericanInviteApproval;