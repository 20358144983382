import React, { Fragment } from 'react';

import useGroupScholasticAllAmericanApprovalForm from './UseGroupScholasticAllAmericanApprovalForm';

import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';
import ReadOnly from '../../../../../../common/components/readOnly/ReadOnly';
import Input from '../../../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../../common/utils/Constants';

const GroupScholasticAllAmericanApprovalForm = ({isRevisit, personOrgGroupId, readonlyFormState, onApprovedClicked, onBackClicked,
  onRejectClicked, onSendBackClicked, onDownloadUploadedFile }) => {
  const {
    isLoading,
    formState,
    errorState,
    updateFormState,
    onSubmitForm
  } = useGroupScholasticAllAmericanApprovalForm(personOrgGroupId, onApprovedClicked, onRejectClicked);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-md-4 pull-right">
          <ActionIntraPageButton type="button" onClick={onDownloadUploadedFile}>
            Download Transcript
          </ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Person Name:"
            name="personName"
            value={readonlyFormState.personName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Group Name:"
            name="groupName"
            value={readonlyFormState.groupName} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="High School Name:"
            name="highSchoolName"
            value={readonlyFormState.highSchoolName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="High School Graduation Year:"
            name="highSchoolGraduationYear"
            value={readonlyFormState.highSchoolGraduationYear} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Para Status:"
            name="paraStatusName"
            value={readonlyFormState.paraStatusName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Open Water Status:"
            name="openWaterStatusName"
            value={readonlyFormState.openWaterStatusName} />
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {isRevisit === true ? 
          <ReadOnly
            label={'Weighted GPA*'}
            name="gpa"
            value={formState.gpa}
           />
           :
          <Input
            label={'Weighted GPA*'}
            name="gpa"
            value={formState.gpa}
            error={errorState.gpa}
            message={errorState.gpa}
            onChange={(value) => { updateFormState('gpa', value); }} />}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
        {isRevisit === true ? 
          <ReadOnly
            label={'Unweighted GPA*'}
            name="unweightedGPA"
            value={formState.unweightedGPA}
           />
           :
          <Input
            label={'Unweighted GPA*'}
            name="unweightedGPA"
            value={formState.unweightedGPA}
            error={errorState.unweightedGPA}
            message={errorState.unweightedGPA}
            onChange={(value) => { updateFormState('unweightedGPA', value); }} />}
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          {isRevisit === false && <Fragment><PrimaryButton type="button" onClick={() => { onSubmitForm(true); }}>Approve</PrimaryButton>&nbsp;</Fragment>}
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
          {isRevisit === false && <Fragment><PrimaryButton type="button" onClick={() => { onSubmitForm(false); }}>Reject</PrimaryButton>&nbsp;</Fragment>}
          {isRevisit === false && <Fragment><PrimaryButton type="button" onClick={onSendBackClicked}>Send Back</PrimaryButton></Fragment>}
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default GroupScholasticAllAmericanApprovalForm;