import { useState, useEffect } from "react";

import validate from './GroupScholasticAllAmericanApprovalFormValidation';

import useMemberGroupsData from "../../../../../../common/state/memberGroups/UseMemberGroupsData";
import useOrgGroupPropertiesData from "../../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData";
import useGroupFieldNamesData from "../../../../../../common/state/groupFieldNames/UseGroupFieldNamesData";

import UseForm from "../../../../../../common/utils/UseForm";
import { createGroupMemberObj } from "../../../../../../common/utils/GroupsUtils";

const INITIAL_FORM_STATE = {
  gpa: '',
  unweightedGPA: ''
};

const useGroupScholasticAllAmericanApprovalForm = (personOrgGroupId, onApprovedClicked, onRejectClicked) => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { memberGroupsState, getGroupMember, putGroupMember } = useMemberGroupsData();
  const [isApprovedState, setIsApprovedState] = useState(false);
  const { formState, errorState, updateFormState, handleSubmit, setErrors
  } = UseForm(INITIAL_FORM_STATE, isApprovedState ? onApprovedClicked : onRejectClicked, () => { });

  useEffect(() => {
    getGroupMember(personOrgGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personOrgGroupId]);

  useEffect(() => {
    if (memberGroupsState.objData?.groupTypeId > 0) {
      const groupTypeId = memberGroupsState.objData.groupTypeId;

      if (orgGroupPropertiesState.isArrayLoaded === false || groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        getOrgGroupProperties(groupTypeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberGroupsState, orgGroupPropertiesState.isArrayLoaded]);

  const onSubmitForm = (isApproved) => {
    const errors = validate(formState);

    if (Object.keys(errors).length === 0) {
      setIsApprovedState(isApproved);

      const memberGroupObj = memberGroupsState.objData;
      const personId = memberGroupObj.personId;
      const orgGroupId = memberGroupObj.orgGroupId;
      const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
        ? memberGroupObj.personOrgGroupQualification[0]
        : undefined;

      if (personId && orgGroupId) {
        const personGroupTypeField = memberGroupObj.personGroupTypeField.map((x) => { return { fieldName: x.groupTypeField?.fieldName, fieldValue: x.fieldValue }; });
        const filteredPersonGroupTypeField = personGroupTypeField.filter((x) => x.fieldName !== GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA && x.fieldName !== GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_UNWEIGHTED_GPA);

        const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [
          ...filteredPersonGroupTypeField,
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA, fieldValue: formState.gpa },
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_UNWEIGHTED_GPA, fieldValue: formState.unweightedGPA },
        ], orgGroupPropertiesState.arrayData, personOrgGroupId, personOrgGroupQualification, personOrgGroupQualification.status);

        const putGroupMemberPromise = putGroupMember(personOrgGroupId, groupMemberObj);

        if (putGroupMemberPromise !== null) {
          putGroupMemberPromise.then((newState) => {
            if (newState !== null) {
              handleSubmit();
            }
          }).catch((e) => {
            //console.log(e);
          });
        }
      }
    } else {
      setErrors(errors);
    }
  };

  return {
    isLoading: memberGroupsState.isObjLoading || orgGroupPropertiesState.isArrayLoading,
    formState,
    errorState,
    updateFormState,
    onSubmitForm
  };
};

export default useGroupScholasticAllAmericanApprovalForm;